'use strict'

import axios from 'axios'
import router from '../router'
import toast from './toast'
import store from '../store'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
let config = {
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60 * 1000 // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function(config) {
    if (store.state.user) {
      config.headers['X-EFRESH-SECRET'] = store.state.secret
      config.headers['X-EFRESH-SESSION'] = store.state.session
    }

    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // 登录失效，跳转到登录页面
    if (response.status == 900 && store.state.user != null) {
      if (JSON.parse(localStorage.userInfo).session === store.state.session) {
        store.commit('LOGOUT')
        store.commit('logntype', 2)
        store.state.release = false
        // 判断当前是否为home页面
        if (router.app.$route.name != 'Home') {
          router.replace('/')
        }else{
           router.go(0)
        }
      }

      return Promise.reject(response.data)
    }

    // 状态码不为200报错
    if (response.status != 200) {
      toast(response.data.msg || '请求失败')
      Promise.reject(response.data)
    }
    // 正常返回
    return response.data
  },
  function(error) {
    // Do something with response error
    let response = error.response
    // 未登录，跳转到登录页面
    if (response && response.status == 900 && store.state.user != null) {
        if (JSON.parse(localStorage.userInfo).session === store.state.session) {
            toast(response.data.msg)
            store.commit('LOGOUT')
            store.commit('logntype', 2)
            store.state.release = false
            // 判断当前是否为home页面
            if (router.app.$route.name != 'Home') {
              router.replace('/')
            }
        } else {
             router.go()
        }

    }
    return Promise.reject(error)
  }
)

export default _axios
/*
Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
*/
