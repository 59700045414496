<template>
  <div class="layout">
    <navbar></navbar>
    <div class="main">
      <div style="display: flex;justify-content: center" v-if="flag" >
        <div
          style="width: 124px;height: 124px;position: fixed;top: 370px;right: 0; z-index:100;"
          @click="$router.push('/signing-privileges')"
        >
          <el-image src="../assets/sign.png"></el-image>
        </div>
      </div>
      <router-view></router-view>
    </div>
    <copyright class="copyright"></copyright>
  </div>
</template>

<script>
import copyright from '@/components/Copyright.vue'
import navbar from '@/components/Navbar.vue'
export default {
  name: 'Layout',
  components: { copyright, navbar },
  computed: {
      user (){
          return this.$store.state.user
      }
  },
  data() {
      return {
          flag:true,
      };
  },
  created(){
         if(['Demo','DemoList'].indexOf(this.$route.name)>-1){
               this.flag=true;
          }else{
              this.flag=false;
          }
  },
  watch: {
      '$route.name':function (newval) {
          if(this.user !=null &&(this.user.webLoginType == 0&&this.user.verifiedStatus == 3)){
              this.flag=true;
          }
          if(['Demo','DemoList'].indexOf(newval)>-1){
               this.flag=true;
          }else{
               this.flag=false;
          }
      }
  }
}
</script>

<style lang="scss" scoped>
.layout {
     background: #f3f3f3;
}
.main {
  background: #f7f7f7;
  width: 100%;
  margin: 0 auto;
}
.copyright {
  background: #1e1b29;
}
</style>
