import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './styles/style/theme/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import request from './utils/request.js'
import toast from './utils/toast.js'
import dbutton from './components/DButton.vue'
import moment from 'moment';
import {toDecimal2} from "@/utils/utils"
import showToast from './components/Popup/index'
import toFindexFn from "./utils/toFindexFn";

Vue.config.productionTip = false

/**
 * get和post二次封装，统一处理错误
 * 如果需要处理错误请求，直接引用request.js
 */
// 对get和post重新封装，不抛出错误信息并挂到vue
Vue.prototype.$get = (url, config = {}) => {
  return new Promise((resolve) => {
    request.get(url, config).then(
      (data) => {
        // 其他错误
        if (data.code && data.code !== 0) {
          toast(data.msg || '请求失败')
          // return Promise.reject(data)
        }
        resolve(data)
      },
      () => {}
    )
  })
}

/**
 *
 * @param url 请求地址
 * @param data 请求数据
 * @param config 请求配置
 * @returns {Promise<unknown>}
 */
Vue.prototype.$post = (url, data = {}, config = {}) => {
  return new Promise((resolve) => {
    request.post(url, data, config).then(
      (data) => {
        // 其他错误
        if (data.code && data.code !== 0) {
          toast(data.msg || '请求失败')
          // return Promise.reject(data)
        }
        resolve(data)
      },
      () => {}
    )
  })
}
//保留两位小数
Vue.prototype.$toFindexFn=toFindexFn
Vue.prototype.$moment = moment
Vue.prototype.$timestampToString = (timestamp) =>
  moment(new Date(timestamp)).format('YYYY-MM-DD HH:mm:ss')
Vue.prototype.$timestampToDate = (timestamp) => {
  if (timestamp == undefined) {
    return ''
  }
  return moment(new Date(timestamp)).format('YYYY-MM-DD')
}

Vue.component('dbutton', dbutton)

Vue.mixin({
  computed: {
    user() {
      return store.state.usermsg
    }
  },
  filters: {
    toDecimal2(x) {
      return toDecimal2(x)
    }
  }
})

Vue.use(ElementUI)
Vue.use(showToast)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
