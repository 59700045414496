<template>
  <div id="app">
    <router-view/>
    <Contact></Contact>
  </div>
</template>
<script>
import Contact from './components/Contact'
export  default {
  components:{
    Contact
  }
}
</script>
<style lang="scss">
// 引入苹方字体,全局
@font-face{
    font-family: "PingFang SC";
    src:url('https://musician-release-1305250541.file.myqcloud.com/web/static/fonts/PingFang Regular.ttf')
}
@font-face {
    font-family: "PingFang SC-Light";
    src: url('https://musician-release-1305250541.file.myqcloud.com/web/static/fonts/PingFang Light.ttf');
}
@font-face {
    font-family: "PingFang SC-Semibold";
    src: url('https://musician-release-1305250541.file.myqcloud.com/web/static/fonts/PingFang Semibold.ttf');
}
@font-face {
  font-family: "PingFang SC-Medium";
  src: url('https://musician-release-1305250541.file.myqcloud.com/web/static/fonts/PingFang Medium.ttf');
}
html,body{
    padding: 0;
    margin: 0;
}
img{
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /*Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
