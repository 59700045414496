<template>
    <div class="toast" v-if="show && message" :class="{hide: !show}">
        {{message}}
    </div>
</template>
<style lang="scss" scoped>
@keyframes show {
    from{opacity: 0;}
    to{opacity: 1;}
}
.toast{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0,0,0,.7);
    border-radius: 3px;
    padding: 10px 15px;
    color: #FFFFFF;
    font-size: 14px;
    animation: show .5s;
    z-index: 3050;
}
.hide{
    display: none;
}
</style>
<script>
/**
 * toast('提示内容')
 * toast('提示内容', {
 *  time: 2000
 * })
 */
export default {
    data(){
        return {
            message: null,
            show: false, // 是否显示
            time: 2000 // 默认保持时间
        }
    },
    methods:{
        toast(message, options = {}){
            this.message = message
            this.show = true
            setTimeout(() => {
                this.show = false
            }, options.time || this.time)
        }
    }
}
</script>
