import Vue from 'vue'
import Toast from '@/components/Toast.vue'


const toast=(message, options) => {
    const Component = Vue.extend(Toast)
    const extendComponent = new Component()
    extendComponent.toast(message, options)
    document.body.appendChild(extendComponent.$mount().$el)
}

Vue.prototype.$toast = toast

export default toast