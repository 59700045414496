<template>
  <div class="works">
    <left-menu :current="$route.meta.name || $route.name" />
    <div class="main" :class="{'no-white-background': $route.meta.noWhiteBackground}">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import LeftMenu from '../components/LeftMenu.vue'
export default {
  name: "LayoutLeftMenu",
  components: {
    LeftMenu
  },
  data(){
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/works.scss';

.main{
  min-height: 860px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
}
.no-white-background {
  border: none;
  padding: 0;
  background: none;
}
</style>
