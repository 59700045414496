<template>
<div>
  <div class="sidebar" @click="dialogVisible = true">
    <img src="../assets/phone.png">
    <span></span>
  </div>
  <el-dialog
      title="联系我们"
      :visible.sync="dialogVisible"
      width="480px"
  >
    <ul>
      <li>
        <img src="../assets/ContactNumber.png">
        <span class="label">
            手机号
        </span>：
        <span>
           17771774047
          </span>
      </li>
      <li>
        <img src="../assets/ContactEmail.png">
        <span class="label">
            邮箱
        </span>：
        <a href="mailto:artist@hikoon.com">
          artist@hikoon.com
        </a>
      </li>
      <li>
        <img src="../assets/ContactWechat.png">
        <span class="label">
            微信号
        </span>：
        <span>
           海葵分贝
          </span>
      </li>
      <li class="qrCode">
        <img src="../assets/WechatQrCode.png">
      </li>
    </ul>
  </el-dialog>
</div>
</template>

<script>
export default {
  name: "Contact",
  data(){
    return{
      dialogVisible:false,
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar{
  position:fixed;
  width: 130px;
  height: 44px;
  right: 0;
  background-color: #FF004D;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  top: 600px;
  z-index: 600;
  line-height: 44px;
  color: #FFFFFF;
  cursor: pointer;
  transition:all .5s;
  overflow: hidden;
  img{
    width: 30px;
    height: 30px;
    vertical-align: middle;
    margin: {
      left: 7px;
      right: 12px;
    };
  }
  //&:hover {
  //  width: 130px;
  //}
  span:after{
    content:'联系我们';
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: LEFT;
    color: #ffffff;
  }
}
::v-deep .el-dialog{
  height: 456px;
  box-sizing: border-box;
  .el-dialog__header{
    padding:{
      top:40px;
      left: 0;
      right: 0;
      bottom: 0;
    };
    text-align: center;
    .el-dialog__title{
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: LEFT;
      color: #222222;
      letter-spacing: 0.01px;
    }
  }
  .el-dialog__body{
    padding:{
      top: 37px;
      right: 0;
      bottom: 0;
      left: 0;
    };
    ul{
      list-style: none;
      padding: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      li{
        width: 240px;
        margin-bottom: 12px;
        font-size: 15px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #222222;
        letter-spacing: 0.01px;
        .label{
          display: inline-block;
          width: 60px;
          text-align: justify;
          text-align-last: justify;
          vertical-align: top;
        }
        img{
          vertical-align: middle;
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }
      a{
        color: #222222;
        white-space: nowrap;
        text-decoration:none
      }
      .qrCode{
        width: 180px;
        height: 180px;
        margin-top: 22px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
