import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Layout from '../components/Layout.vue'
import LayoutLeftMenu from '../components/LayoutLeftMenu'
import request from "@/utils/request"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  //  认证
  {
    path: '/certification',
    name: 'certification',
    redirect: '/certification/certificationFrom',
    component: () => import('../views/certification.vue'),
    children: [
      {
        path: '/certification/certificationFrom',
        name: 'certificationFrom',
        component: () => import('../views/certificationFrom.vue')
      },
      // 认证提交后页面
      {
        path: '/certification/examine',
        name: 'Examine',
        component: () => import('../views/Examine.vue')
      }
    ]
  },
  {
    path: '/user',
    name: 'User',
    component: Layout,
    redirect: '/certify',
    children: [
      {
        path: '/layout-left-menu',
        name: 'LayoutLeftMenu',
        component: LayoutLeftMenu,
        children: [
          {
            path: '/personal',
            name: 'Personal',
            redirect: '/personal/info',
            meta: {},
            component: () => import('@/views/personalInfo'),
            children: [
              {
                path: 'info',
                name: 'Info',
                meta: {
                  noWhiteBackground: true,
                  personal: true
                },
                component: () => import('@/views/personalInfo/info')
              },
              {
                path: 'account-security',
                name: 'AccountSecurity',
                meta: {
                  noWhiteBackground: true,

                },
                component: () => import('@/views/personalInfo/accountSecurity')
              },
              {
                path: 'payment-settings',
                name: 'PaymentSettings',
                meta: {
                  personal: true,
                  noWhiteBackground: true
                },
                component: () => import('@/views/personalInfo/paymentSettings')
              },
              {
                path: 'company-certification',
                name: 'CompanyCertification',
                meta: {
                  noWhiteBackground: true
                },
                component: () => import('@/views/personalInfo/companyCertification')
              },
            ]
          },
          {
            path: '/company',
            name: 'Company',
            redirect: '/company/info',
            meta: {},
            component: () => import('@/views/companyInfo'),
            children: [
              {
                path: 'info',
                name: 'CompanyInfo',
                meta: {
                  noWhiteBackground: true,
                  personal: false,
                  name: 'Company'
                },
                component: () => import('@/views/personalInfo/info')
              },
              {
                path: 'payment-settings',
                name: 'CompanyPaymentSettings',
                meta: {
                  noWhiteBackground: true,
                  name: 'Company'
                },
                component: () => import('@/views/personalInfo/paymentSettings')
              },
              {
                path: 'permission',
                name: 'CompanyPermission',
                meta: {
                  noWhiteBackground: true,
                  name: 'Company'
                },
                component: () => import('@/views/administration/permission')
              },
            ]
          },
          {
            path:'/issue/:id?/:ver?/:songId?',
            name: 'Issue',
            props:true,
            meta: {
              noWhiteBackground: true,
              permissionId:6,
            },
            component: () => import('@/views/issue')
          },
          {
            path:'/query/:id?/:ver?/:songId?',
            name: 'IssueQuery',
            props: true,
            meta: {
              noWhiteBackground: true,
              permissionId:6,
            },
            component:()=> import('@/views/issue')
          },
          {
            path: '/published-list',
            name: 'PublishedList',
            component: () => import('@/views/PublishedList')
          },
          {
            path: '/published-view',
            name: 'PublishedView',
            props: true,
            component: () => import('@/views/PublishedView')
          },
          {
            path: '/income',
            name: 'Income',
            component: () => import('../views/income/Income'),
            meta:{
              active: true,
              permissionId: 10,
              noWhiteBackground: true
            }
          },
          {
            path: '/unchecked',
            name: 'Unchecked',
            component: () => import('../views/income/Unchecked')
          },
          // 我的收益-申请提现-提现
          {
            path: '/withdrawal/:company',
            props: true,
            name: 'withdrawal',
            component: () => import('../views/Withdrawal.vue')
          },
          // 我的收益-申请提现-提现成功
          {
            path: '/withdrawal-success',
            name: 'withdrawalSuccess',
            component: () => import('../views/WithdrawalSuccess.vue')
          },
          {
            path: '/confirm',
            name: 'Confirm',
            component: () => import('../views/income/Confirm')
          },
          {
            path: '/bills',
            name: 'Bills',
            component: () => import('../views/income/Bills')
          },
          {
            path: '/cash-out-list',
            name: 'Cash-outList',
            component: () => import('../views/income/Cash-outList')
          },
          {
            path: '/cashing-records',
            name: 'CashingRecords',
            component: () => import('../views/income/CashingRecords')
          },
          //意见反馈
          {
            path:'/feedback/:id',
            name:'Feedback',
            component:()=>import('../views/income/Feedback')
          },
          // 提现记录详情
          {
            path:'/cashing/:id',
            name:'Cashing',
            component:()=>import("../views/income/Cashing")
          },
          //提现反馈页面
          {
            path:'/feedbackpage',
            name:'FeedbackPage',
            component:()=>import('../views/income/FeedbackPage')
          },
          //收益详情
          {
            path: '/bill/:id',
            name: 'Bill',
            component: () => import('../views/income/Bill')
          },
          // 发票管理
          {
            path:'/cash-out/:id',
            name:'Cash-out',
            component:()=>import('../views/income/Cash-out')
          },
          {
            path:'/trends',
            name:'trends',
            component:()=>import('../views/data-center/trends'),
            meta:{
              active: true,
              permissionId: 11
            }
          },
          {
            path:'/trend/:id',
            name:'trend',
            props: true,
            component:()=>import('../views/data-center/trend'),
            meta:{
              active: true,
              permissionId: 11
            }
          },
          {
            path:'/album/:id',
            name:'album',
            props: true,
            component:()=>import('../views/songManage/Album'),
            meta:{
              active: true,
              permissionId: 8
            }
          },
          {
            path:'/song/:id',
            name:'song',
            props: true,
            component:()=>import('../views/songManage/Song'),
            meta:{
              active: true,
              permissionId: 8
            }
          },

          // 机构管理
          {
            path: '/permission',
            name:'Permission',
            component:()=>import('../views/administration/permission'),
            meta:{
              active: true,
              permissionId: 15
            }
          },
          {
            path:'/artist-management',
            name:'ArtistManagement',
            component: ()=>import('../views/administration/artistManagement'),
            meta: {
              active: true,
              permissionId: 16,
            }
          }
        ]
      },
      // 我的收益-申请提现-提现记录
      {
        path: '/withdrawal-list',
        name: 'withdrawalList',
        component: () => import('../views/WithdrawalList.vue')
      },
      // 我的收益-申请提现-提现详情
      {
        path: '/withdrawalDetail/:id',
        name: 'withdrawalDetail',
        component: () => import('../views/WithdrawalDetail.vue')
      },
      // 我的收益-申请提现
      {
        path: '/incomeList',
        name: 'incomeList',
        component: () => import('../views/IncomeList.vue')
      },
      //   音乐人认证
      {
        path: '/certify',
        name: 'Certify',
        component: () => import('../views/Certify.vue')
      },
      //  实名认证
      {
        path: '/verify',
        name: 'Verify',
        component: () => import('../views/Verify.vue')
      },
      //音乐人认证
      {
        path: '/musiciancon',
        name: 'Musiciancon',
        component: () => import('../views/Musiciancon.vue')
      },
      {
        path: '/works',
        name: 'Works',
        component: () => import('../views/Works.vue')
      },
      // {
      //   path: '/publish',
      //   name: 'Publish',
      //   component: () => import('../views/Publish.vue')
      // },
      {
        path: '/tme',
        name: 'TME',
        component: () => import('../views/TME.vue')
      },
      {
        path: '/demo/:id',
        props: true,
        name: 'Demo',
        component: () => import('../views/Demo'),
        meta:{
          active:true,
          permissionId: 7
        }
      },
      {
        path: '/demo-list',
        name: 'DemoList',
        component: () => import('../views/DemoList'),
        meta:{
          active: true,
          permissionId: 9
        }
      },
      {
        path: '/applyList',
        name: 'applyList',
        component: () => import('../views/issue/applyList.vue'),
        meta:{
          active: true,
          permissionId:8,
        }
      },
      {
        name:'songUploadRecord',
        path:'/songUploadRecord/:type?',
        component:()=>import('../views/issue/songUploadRecord.vue'),
        props:true,
        meta:{
          active:true,
          permissionId:8,
        },
      },
      {
        name:'songFileDetails',
        path:'/songFileDetails/:songId',
        component:()=>import('../views/issue/songFileDetails'),
        props:true,
        meta:{
          active:true,
          permissionId:8,
        },
      },
      {
        path: '/updateApplyList',
        name: 'updateApplyList',
        component: () => import('../views/issue/updateApplyList.vue'),
        meta:{
          active: true,
          permissionId:8,
        }
      },
      {
        path: '/draftList',
        name: 'draftList',
        component: () => import('../views/issue/draftList.vue'),
        meta:{
          active: true,
          permissionId:8,
        }
      },
      {
        path: '/demo-view/:id',
        props: true,
        name: 'DemoView',
        component: () => import('../views/DemoView'),
        meta:{
          active: true,
          permissionId: 9
        }
      },
      {
        path: '/contract/:id',
        props: true,
        name: 'Contract',
        component: () => import('../views/Contract')
      },
      {
        path: '/signing-privileges',
        name: 'SigningPrivileges',
        component: () => import('../views/SigningPrivileges')
      },
      //   消息页面
      {
        path: '/news',
        name: 'News',
        component: () => import('../views/News.vue')
      },
      // 账户与安全
      {
        path: '/accountandsecurity',
        name: 'Accountandsecurity',
        component: () => import('../views/Accountandsecurity.vue')
      },
      // 详情
      {
        path: '/consulthome',
        name: 'Consulthome',
        component: () => import('../views/ConsultHome.vue')
      },
      //   活动中心
      {
        path: '/activity',
        name: 'Activity',
        component: () => import('../views/Activity.vue')
      },
      // 用户选歌
      {
        path: '/electsong',
        name: 'Electsong',
        component: () => import('../views/ElectSong.vue')
      },
      // 详情
      {
        path: '/details',
        name: 'Details',
        component: () => import('../views/Details.vue')
      },
      //  提交
      {
        path: '/submitwork',
        name: 'Submitwork',
        component: () => import('../views/SubmitWork.vue')
      },
      // 报名详情
      {
        path: '/detailssing/:id',
        name: 'Detailssing',
        component: () => import('../views/DetailsSing.vue')
      },
      // 银行卡设置
      {
        path: '/setbankcard',
        name: 'Setbankcard',
        redirect: '/setbankcard/Msmchecking',
        component: () => import('../views/Setbankcard.vue'),
        children: [
          {
            path: 'Msmchecking/:identity',
            name: 'Msmchecking',
            props:true,
            component: () => import('@/components/Msmchecking.vue')
          },
          {
            path: 'setcard/:identity',
            name: 'Setcard',
            props:true,
            component: () => import('@/components/Setcard.vue')
          },
          {
            path: 'subbank',
            name: 'Subbank',
            component: () => import('@/components/Subbank.vue')
          }
        ]
      },
      //   收益明细
      {
        path: '/revenue',
        name: 'Revenue',
        component: () => import('../views/Revenue.vue')
      },
      {
        path: '/incomeDetails',
        name: 'IncomeDetails',
        component: () => import('../views/IncomeDetails.vue')
      },
      // 收益详情
      {
        path: '/billdetails',
        name: 'Billdetails',
        component: () => import('../views/BillDetails.vue')
      },
      // 收益成功
      {
        path: '/billdetailSuccess',
        name: 'BilldetailSuccess',
        component: () => import('../views/BilldetailSuccess.vue')
      },
      //   分贝学院
      {
        path: '/taskrun',
        name: 'Taskrun',
        component: () => import('../views/train/TaskRun.vue')
      },
      //   提交作业
      {
        path: '/submitjob',
        name: 'Submitjob',
        component: () => import('../views/train/Submitjob.vue')
      },
      //作品详情
      {
        path: '/work-details',
        name: 'WorkDetails',
        component: () => import('../views/train/WorkDetails.vue')
      },
      // 视频作业详情
      {
        path: '/video-details/:id',
        name: 'VideoDetails',
        component:()=>import('../views/train/VideoDetails.vue')
      },
      //提交作业成功页面
      {
        path: '/submitted-success',
        name: 'SubmittedSuccess',
        component: () => import('../views/train/SubmittedSuccess.vue')
      },
    // 创作中心
      {
        path:'/create',
        name:'Create',
        component:()=>import('../views/create/Index.vue')
      },
      // 帮助中心
      {
        path: '/help-home',
        name: 'HelpHome',
        component :()=>import('../views/help/HelpHome')
      },
    //  歌曲管理1.8.8
      {
        path: '/songManage-home',
        name: 'songManageHome',
        component:()=>import('../views/songManage/index'),
        children:[
          {
            path:'/song-manage',
            name:'songManage',
            component:()=>import('../views/songManage/songManage'),
            meta:{
              active: true,
              permissionId: 8
            }
          },
          {
            path:'/song-draft',
            name:'songDraft',
            component:()=>import('../views/songManage/songDraft'),
            meta:{
              active: true,
              permissionId: 8
            }
          }
        ]
      },
        // 发布歌曲1.8.8
      {
        path: '/release/:id',
        name:'Release',
        component:()=>import('../views/release/release'),
        meta:{
          active:true,
          permissionId: 6
        }
      },
      {
        path:'/member-management',
        name:'memberManagement',
        component:()=>import('../views/administration/memberManagement'),
        meta:{
          active: true,
          permissionId: 14
        }
      },
      {
        path:'/member-dynamic',
        name:'memberDynamic',
        component:()=>import('../views/administration/memberDynamic'),
        meta:{
          active: true,
          permissionId: 13
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'LoginAndJoin',
    component: () => import('../views/LoginAndJoin'),
  },
  {
    path: '/apifox',
    name: 'Apifox',
    component: () => import('../views/tools/apifox'),
  },
  {
      path:"/invitation-details/:code",
    name: "InvitationDetails",
    component: ()=> import('../views/InvitationDetails'),
  },
  {
    path: '/share/:code?',
    name: "Share",
    props: true,
    component: ()=> import('../views/share'),
  },
  {
    path: '/share-musician/:code?',
    name: "shareMusician",
    props: true,
    component: ()=> import('../views/share'),
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  if (
    !store.state.user &&
    ['Home', 'Activity', 'Consulthome', 'Details', 'Detailssing', 'HelpHome', 'LoginAndJoin','login','InvitationDetails','Share'].indexOf(
      to.name
    ) < 0
  ) {
    if (from.name != 'Home') {
      next({ name: 'Home' })
    }
  } else if (
    [
      'Home',
      'Certify',
      'Verify',
      'certificationFrom',
      'Examine',
      'Musiciancon',
      'News',
      'Detailssing',
      'Activity',
      'Consulthome',
      'Details',
        'HelpHome',
        'InvitationDetails',
        'Info',
        'AccountSecurity',
        'PaymentSettings',
        'CompanyCertification'

    ].indexOf(to.name) < 0
  ) {
    // 不在首页登录页验证页
    // 个人身份
    // 0未提交 1已提交，待审核 2审核失败 3审核成功

    let msg = store.state.usermsg || store.state.user || {}
    if (msg.webLoginType == 0 && msg.verifiedStatus != 3) {
      next('/')
    }
    if (msg.webLoginType == 1 && msg.userCompanyVo.status != 1) {
      next('/')
    }
    // 机构已认证
    if(msg.webLoginType === 1){
      // 不是管理员
      if(msg.userCompanyVo.managerUser !== 1){
        // 需要权限的页面
        if(to.meta && to.meta.permissionId){
          // 没有菜单权限数据，获取菜单数据
          if(store.state.roleInfo.subIds === undefined){
            const r = await request.get('/api/menu/userMenu')
            if(r.code === 0) {
              store.commit('SET_ROLE_IDS', r.data || [])
            }
          }

          console.log(store.state.roleInfo.subIds)
          // 没有权限
          if(!store.state.roleInfo.subIds.find(e => e === to.meta.permissionId)) {
            next('/')
          }
        }

      }
    }
    // 认证状态 0未提交 1已提交，待审核 2审核失败 3审核成功
    // if (store.state.user.verifiedStatus === 1) {
    //   // 已提交
    //   next({ name: 'Verify', query: { status: 1 } })
    // }
    next()
    //  else if (store.state.user.verifiedStatus !== 3) {
    //   // 不是验证成功
    //   next({ name: 'Certify' })

    // } else next()
  } else {
    next()
  }
})
export default router
