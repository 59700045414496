import {PRIVACY, AGREEMENT} from './const'
import moment from 'moment';

// 打开隐私政策
export function goPrivacy() {
    window.open(PRIVACY)
}

// 打开 用户协议
export function goAgreement() {
    window.open(AGREEMENT)
}

// 生成一个uid
export function getUid(len = 20){
	let str = ''
	while(len){
		len--
		str += (Math.random() * 36 | 0).toString(36)
	}
	return str
}

// 页面显示状态 -1删除 0草稿 1待审核 3驳回 4已上架(出售中) 5已下架 6锁定 7售出 8分贝音乐人下架中 9分贝音乐人已下架
export function getStatusByTotalStatus(id){
  switch (id){
    case -1: return '删除'
    case 0: return '草稿'
    case 1: return '待审核'
    case 3: return '已驳回'
    case 4: return '出售中'
    case 5: return '已下架'
    case 6: return '锁定'
    case 7: return '已售出'
    case 8: return '下架中'
    case 9: return '已下架'
  }
}
// 计算时间
export function filterTime(val) {
  var week = [
    '星期日',
    '星期一',
    '星期二',
    '星期三',
    '星期四',
    '星期五',
    '星期六'
  ]
  val = new Date(val)
  let time = new Date()
  //相差天数
  let tian = Math.floor(time - val) / (24 * 3600 * 1000)
  if (time.getDate() - val.getDate() == 0) {
    if (tian * 24 * 60 > 5) {
      if (moment(val).format('a') == 'am') {
        return `上午 ${moment(val).format('hh:mm')}`
      } else {
        return `下午 ${moment(val).format('hh:mm')}`
      }
    } else {
      let mm = parseInt(tian * 24 * 60)

      return `${mm != 0 ? mm + '分钟前' : '刚刚'}`
    }
  } else if (time.getDate() - val.getDate() == 1) {
    if (moment(val).format('a') == 'am') {
      return `昨天 上午 ${moment(val).format('hh:mm')}`
    } else {
      return `昨天 下午 ${moment(val).format('hh:mm')}`
    }
  } else if (
    time.getDate() - val.getDate() < 8 &&
    time.getDate() - val.getDate() > 1
  ) {
    let dd = moment().day() - moment(val).day()
    var st
    if (dd > 0) {
      //  console.log(`${week[moment(val).day()]} ${ moment(val).format('HH:mm')}`)
      st = `${week[moment(val).day()]} ${moment(val).format('HH:mm')}`
    } else {
      //  console.log(`上周 ${week[moment(val).day()]} ${ moment(val).format('HH:mm')}`)
      st = `上周 ${week[moment(val).day()]} ${moment(val).format('HH:mm')}`
    }
    return st
  } else {
    return moment(val).format('YYYY年MM月DD日 HH:mm')
  }
}

/**
 * 显示两位小数
 * @param x
 * @returns {string|boolean}
 */
export function toDecimal2(x) {
  let f = parseFloat(x)
  if (isNaN(f)) {
    return false;
  }
  f = Math.round(x * 100) / 100
  let s = f.toString()
  let rs = s.indexOf('.')
  if (rs < 0) {
    rs = s.length;
    s += '.';
  }
  while (s.length <= rs + 2) {
    s += '0';
  }
  return s;
}

/**
 * @description 数据字典翻译
 * @param {any[]} dictionary 字典数组
 * @param {string} value 要被翻译的值
 * @param {string} labelKey 字典中的值名称
 * @param {string} valueKey 字典中的键名称
 * @returns {string}
 */
export function getValueByLabel(dictionary, value, labelKey = 'label', valueKey = 'value'){
  let result = ''

  dictionary.forEach(d => {
    if (d[labelKey] === value)
      result = d[valueKey]
  })

  return result
}
