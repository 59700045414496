  function  toFindexFn (x){
    x=Number(x)
    if(parseInt(x)===x){
        return x.toFixed(2)
    }else if(!isNaN(x)){
        if(x.toString().split('.')[1].length>2){
            return Math.floor(x*100)/100
        }else {
            return x.toFixed(2)
        }
    }else {
        return '0.00'
    }
}

export  default toFindexFn