<template>
    <button class="dbutton" :class="{primary: type == 'primary', plain: type=='plain', disabled}" @click="onClick">
        <slot/>
    </button>
</template>
<script>
/*
*/
export default {
    props:{
        // 是否禁用
        disabled:{
            type: Boolean,
            default: false
        },
        // 按钮类型（样式）
        type:{
            type: String,
            default: 'primary' // 可选gray
        },
        // 禁用状态是否可点击
        clickable:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return {


        }
    },
    methods:{
        onClick(e){
            if(this.disabled && !this.clickable){
                return false
            }
            this.$emit('click', e)
        }
    }
}
</script>
<style scoped lang="scss">
.dbutton{
    width: 202px;
    height: 50px;
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;

    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    outline: none;
}
.primary{
    background: $base-color;
    color: #FFFFFF;
}
.plain{
    background: #EFEFEF;
    color: #666666;
}
.disabled{
    opacity: 0.5;
}
</style>
