import vue from 'vue'
import POpup from './Popup.vue'

// 生成一个扩展实例构造器
const ToastConstructor = vue.extend(POpup)
function showToast({
  msg = '',
  title = '提示',
  type = 'warning',
  duration = 1000
}) {
  const _toast = new ToastConstructor({
    data() {
      return {
        flag: true,
        msg: msg,
        title: title,
        type: type,
        duration: Number(duration)
      }
    }
  })
  let element = _toast.$mount().$el
  document.body.appendChild(element)

  //   duration时间到了后隐藏
  setTimeout(() => {
    _toast.flag = false
  }, duration)
}
showToast.install = (Vue) => {
  // 将组件注册到 vue 的 原型链里去,
  // 这样就可以在所有 vue 的实例里面使用 this.$popup()
  Vue.prototype.$popup = showToast
}

export default showToast
