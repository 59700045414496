import Vue from 'vue'
import Vuex from 'vuex'
import request from "@/utils/request"

Vue.use(Vuex)

let localUserInfo = localStorage.userInfo
if(localUserInfo){
  localUserInfo = JSON.parse(localUserInfo)
}
let hiddestat = localStorage.hiddestat
let song_details = localStorage.songdetails
if (song_details) {
  song_details = JSON.parse(song_details)
}
export default new Vuex.Store({
  state: {
    secret: localUserInfo ? localUserInfo.secret : null,
    session: localUserInfo ? localUserInfo.session : null,
    user: localUserInfo ? localUserInfo.user : null,
    // 登入流程
    // 0:未注册 1:已登录未认证 2：未登录
    loginflag: 0,
    // 首页厂牌入驻
    release: false,
    //入驻按钮时候是否隐藏
    hiddestate: hiddestat,
    // 消息
    unReadTotalNum: '',
    // 立即入住按钮
    check: false,
    // 获取个人最新信息
    usermsg: '',
    // 歌曲详情
    Songdetails: song_details || {},
    // 获取活动id
    activityId: '',
    // 轮播图初始位置
    bannerindex: 0,
    // 角色权限
    roleInfo: {},
    album:undefined,
    other:undefined,
    song:undefined,
    singer:undefined,
    companyList:[]
  },
  mutations: {
    LOGIN(state, data) {
      state.secret = data.secret
      state.session = data.session
      state.user = data.user
      localStorage.userInfo = JSON.stringify(data)
    },
    LOGOUT(state) {
      state.secret = null
      state.session = null
      state.user = null
      state.usermsg = ''
      localStorage.removeItem('userInfo')
      localStorage.removeItem('hiddestat')
      localStorage.removeItem('songdetails')
      localStorage.removeItem('release')
    },
    UPDATE_VERIFIED_STATUS(state, verifiedStatus) {
      // 认证状态 0未提交 1已提交，待审核 2审核失败 3审核成功
      state.user.verifiedStatus = verifiedStatus
      localStorage.userInfo = JSON.stringify({secret:state.secret,session:state.session,user:state.user})
    },
    logntype(state, val) {
      state.loginflag = val
    },
    hiddenBut(state, val) {
      state.hiddestate = val
      // 立即注册按钮显示
      if (val.userCompanyVo == null && val.verifiedStatus == 0) {
        localStorage.setItem('hiddestat', 0)
        state.hiddestate = 0
      } else if (val.verifiedStatus == 0 && val.userCompanyVo != null) {
        // 入驻音乐人按钮显示
        localStorage.setItem('hiddestat', 1)
        state.hiddestate = 1
      } else if (val.verifiedStatus != 0 && val.userCompanyVo == null) {
        //   机构认证按钮显示
        localStorage.setItem('hiddestat', 2)
        state.hiddestate = 2
      } else if (val.verifiedStatus == 2 && val.userCompanyVo.status == 2) {
        // 音乐人认证和机构认证都失败
        localStorage.setItem('hiddestat', 0)
        state.hiddestate = 0
      } else if (val.verifiedStatus == 2 && val.userCompanyVo.status == 0) {
        // 音乐认证失败，机构认证审核中
        localStorage.setItem('hiddestat', 1)
        state.hiddestate = 1
      } else if (val.verifiedStatus == 1 && val.userCompanyVo.status == 2) {
        // 音乐认审核中，机构认证审核失败
        localStorage.setItem('hiddestat', 2)
        state.hiddestate = 2
      } else {
        localStorage.setItem('hiddestat', null)
        state.hiddestate = null
      }
      if (val.userCompanyVo != null) {
        if (val.verifiedStatus == 0 && (val.userCompanyVo.status == 2 || val.userCompanyVo.status == 3)) {
          //  音乐人未认证 机构认证失败
          localStorage.setItem('hiddestat', 0)
          state.hiddestate = 0
        }
      }
    },
    //获取实时的个人信息
    UMSG(state, val) {
      state.usermsg = val
      this.dispatch('getRoleIds')
    },
    FNDETAILS(state, val) {
      state.Songdetails = val
      localStorage.setItem('songdetails', JSON.stringify(val))
    },
    SET_ROLE_IDS(state, val) {
      state.roleInfo = {subIds: val}
    },
  //  获取统一常量
    UNIFORM_CONSTANT(state, val){
      state.album = val.album;
       state.other = val.other;
       state.song = val.song;
      state.singer = val.singer;
    },
    GET_COMPANY(state,val){
      state.companyList = val;
    }
  },
  actions: {
    getRoleIds({ commit, state }){
      const roleId = state.usermsg.userCompanyVo ? state.usermsg.userCompanyVo.companyRoleId :undefined
      if(!roleId) return
      request.get('/api/menu/userMenu').then(r => {
          if(r.code === 0) {
            commit('SET_ROLE_IDS', r.data || [])
          }
      })
    },
    getUniformConstant({commit}){
      request.post('/api/issue/universal/constant',{}).then(r => {
        if(r.code === 0){
          commit('UNIFORM_CONSTANT',r.data || {});
        }
      })
    },
    getMechanismList({commit}){
      request.post('/api/user/companyList',{type:1}).then(r => {
        if(r.code === 0){
          commit('GET_COMPANY',r.data)
        }
      })
    }
  },
  modules: {}
})
