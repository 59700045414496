<template>
  <div class="navbar" :class="backflag ?'bg':''">
    <div class="content">
      <logo class="left"></logo>
      <div class="right">
        <div v-if="login" class="home">海葵分贝首页</div>
        <template v-else>
          <qrcode/>
          <!-- 消息 -->
          <!-- 身份切换 -->
          <div class="identity" v-if="user && falg">
            <button
                v-if="$store.state.hiddestate == 0"
                @click="moveinnow"
            >
              立即入驻
            </button>
            <button
                class="but"
                v-if="$store.state.hiddestate == 1"
                @click="approval(0)"
            >
              入驻音乐人
            </button>
            <button
                class="but"
                v-if="$store.state.hiddestate == 2"
                @click="approval(1)"
            >
              入驻厂牌/机构
            </button>
          </div>
          <div class="message" @click="gomessage" v-if="user">
            <el-badge :value="$store.state.unReadTotalNum" class="item">
              <img src="../assets/examine/smallbell.png" alt=""/>
            </el-badge>
          </div>
          <div
              class="nickname"
              v-if="user"
              @click="goWorks"
          >
            <el-popover
                popper-class="el-popover-homepage"
                placement="bottom"
                trigger="hover"
            >
              <div class="new-exit">
                <div class="header">
                  <div class="left">
                    <el-image fit="cover" :src="  user.avatar"></el-image>
                    <div class="user">
                      <router-link style="text-decoration: none;" to="/personal/info">
                        <div>{{user.name}} ></div>
                      </router-link>
                      <div v-if="identityJudgment" >当前身份：{{idType}}</div>
                    </div>
                  </div>
                  <div class="right"   @click="swict(0)" v-if="user.webLoginType === 1">
                    切换到音乐人
                  </div>
                </div>
                <div class="line"></div>
              <div class="content">
                <el-collapse class="el-collapse-homePage">
                  <el-collapse-item>
                    <template slot="title">
                     <el-image style="width: 16px;height: 16px;margin-right: 9px;" :src="require('../assets/qiehuan.png')"></el-image> 切换机构
                    </template>
                    <div class="box">
                      <div class="mechanism-item" @click="swict(item.id,1)" v-for="(item,index) in companyList" :class="{'mechanism-item-active' : item.id === companyId}"  :key="index">
                        <div class="left">
                          <el-image fit="cover" :src="item.logo"></el-image>
                          <div>
                           {{item.name}}
                          </div>
                        </div>
                        <div class="right">
                          <img style="width: 16px;height: 16px;" src="../assets/determine-issue-icon.png" alt="">
                        </div>
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
                <router-link v-show="isAuditSucceeded" to="/certification/certificationFrom" style="text-decoration: none;">
                  <div style="font-size: 14px;font-family: PingFang SC, PingFang SC-Regular;font-weight: 400;text-align: LEFT;color: #333333;"><el-image style="width:16px;height:16px;vertical-align: middle;margin-right: 9px;" :src="require('../assets/add-mechanism.png')" />认证更多机构</div>
                </router-link>
              </div>
                <div class="line" style="margin-top: 20px;"></div>
                <div class="login" @click.stop.prevent="logout" >退出登录</div>
              </div>
             <span slot="reference"> {{
                  user.webLoginType === 1 && user.userCompanyVo !== null
                      ? user.userCompanyVo.name
                      : user.name
                }}
              </span>
            </el-popover>
          </div>
          <div v-else class="btn" @click="gologin()">登录/注册</div>
        </template>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.el-popover-homepage{
  padding: 0 !important;
  .exit {
    width: 276px;
    height: 121px;
    padding:10px 0 0 0;
    .account {
      height: 90px;
      box-sizing: border-box;
      padding: 0px 8px;
      ul {
        height: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: justify;

        li:nth-child(1) {
          display: flex;
          justify-content: space-between;

          div {
            color: black;
          }

          .right2 {
            padding: 0px 10px;
            text-align: center;
            height: 24px;
            background: #ff004d;
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 24px;
            cursor: pointer;
            ::v-deep .el-icon-sort {
              transform: rotate(90deg);
            }
          }
        }

        li:nth-child(2) {
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 22px;
          margin-top: 8px;

          img {
            vertical-align: middle;
            width: 26px;
            height: 26px;
          }

          span {
            vertical-align: middle;
            margin-left: 10px;
          }
        }
      }
    }

    .login {
      height: 40px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 40px;
      text-align: center;
      // padding-top: 20px;
      border-top: 1px solid #dddddd;
    }
  }
  >.new-exit{
    width: 380px;
    padding: 0 20px;
    box-sizing: border-box;
    .header{
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left{
        display: flex;
        align-items: center;
       .user{
         cursor: pointer;
         margin-left: 10px;
         div:first-child{
           margin-bottom: 8px;
           font-size: 14px;
           font-family: PingFang SC, PingFang SC-Regular;
           font-weight: 400;
           text-align: LEFT;
           color: #000000;
         }
         div:last-child{
           font-size: 12px;
           font-family: PingFang SC, PingFang SC-Regular;
           font-weight: 400;
           text-align: LEFT;
           color: #aaaaaa;
           line-height: 12px;
         }
       }
        .el-image{
          width: 50px;
          height: 50px;
          border-radius:50%;
        }
      }
      .right{
        width: 100px;
        height: 28px;
        background: #ff004d;
        border-radius: 2px;
        line-height: 28px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
      }

    }
    .line{
      width: 100%;
      height: 1px;
      background: #f7f7f7;
    }
    .content{
      >.el-collapse-homePage{
        border: none;
        .el-collapse-item__header{
          border: none;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #333333;
        }
        .el-collapse-item__wrap{
          border: none;
          .box{
            max-height: 250px;
            overflow-y: scroll;
            .mechanism-item{
              cursor: pointer;
              padding: 10px 20px ;
              display: flex;
              align-items: center;
              justify-content:space-between;
              &.mechanism-item-active{
               .left{
                 color: #ff004d;
               }
                .right{
                  opacity: 1;
                }
              }
              >.left{
                display: flex;
                align-items: center;
                font-size: 14px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
                text-align: LEFT;
                color: #666666;
                .el-image{
                  border-radius:50%;
                  width: 28px;
                  height: 28px;
                  margin-right: 8px;
                }
              }
              .right{
                opacity: 0;
              }
            }
            &::-webkit-scrollbar {
              width:5px;
            }
            /* 滚动槽 */
            &::-webkit-scrollbar-track {
              border-radius:10px;
            }
            /* 滚动条滑块 */
            &::-webkit-scrollbar-thumb {
              border-radius:10px;
              background:rgba(0,0,0,0.1);

            }
          }

        }
      }
    }

    .login{
      cursor: pointer;
       padding: 16px 0;
      text-align: center;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #666666;
    }
  }
}

</style>
<style lang="scss" scoped>
.navbar {
  height: 56px;
  background: #1e1b29;
  width: 100%;
  display: flex;
  justify-content: center;
  .content {
    box-sizing: border-box;
    width: 1490px;
    max-width: 1490px;
    min-width: 1140px;
    padding: 0 20px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .left {
    flex: 1;
  }

  .right {
    display: flex;
    align-items: center;

    .nickname {
      margin-left: 32px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 17px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .home {
      height: 21px;
      font-size: 15px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 21px;
    }

    .btn {
      width: 80px;
      height: 26px;
      background: $base-color;
      border-radius: 2px;
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 19px;
      cursor: pointer;
    }

    .message {
      cursor: pointer;
      margin-left: 30px;

      img {
        width: 24px;
        height: 24px;
        margin-top: 7px;
      }
    }

    ::v-deep .el-badge__content {
      margin-top: 10px;
      background-color: red;
      border: none;
    }

    .identity {
      cursor: pointer;

      button {
        margin-left: 30px;
        width: 98px;
        height: 26px;
        background: #ff004d;
        border-radius: 2px;
        border: none;
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }

      button.but {
        color: #ffffff;
        background-color: rgba(255, 255, 255, 0);
        border: 1px solid #ffffff;
      }
    }
  }

}

.bg {
  background-color: #000000
}
</style>
<script>
import logo from '@/components/Logo.vue'
import qrcode from '@/components/Qrcode.vue'

export default {
  name: 'Navbar',
  components: {logo, qrcode},
  props: {
    // 登录页面特有的，右侧显示返回首页链接
    login: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userCompanyVoID: null,
      falg: true,
      backflag: false,
    }
  },
  computed:{
    idType(){
      return this.user.webLoginType === 0 ? (this.user.userTreaty || '合作音乐人') : this.user.userCompanyVo ? this.user.userCompanyVo.companyType === 1 ? '合约机构':'认证机构' :''
    },
    userInfo(){
      return this.user
    },
    companyId(){
     return this.user.webLoginType === 1 ? this.user?.userCompanyVo?.id : -1
    },
    companyList(){
      return this.$store.state.companyList
    },
    isAuditSucceeded(){
      return this.companyList.every(e => e.status === 1 )
    },
    identityJudgment(){
      return this.user.webLoginType === 0 ? this.user.verifiedStatus === 3 : this.user.userCompanyVo?.status === 1
    }
  },
  created() {
    //  添加背景颜色
    if (this.$route.name === 'Activity' || this.$route.name === 'Consulthome') {
      this.backflag = true
    } else {
      this.backflag = false
    }

    if (this.$route.name == "Musiciancon" || this.$route.name == 'Examine') {
      this.falg = false;
    }
    if (this.$store.state.user !== null) {
      this.$post('/api/user/userMsg').then(res => {
        // this.userCompanyVoID=res.data.userCompanyVo.id
        this.$store.commit('hiddenBut', res.data)
        this.$store.commit('UMSG', res.data)
        if( res.data.webLoginType === 1 && res.data.userCompanyVo !== null && res.data.userCompanyVo.companyType === 1)this.$store.dispatch('getUniformConstant');
      })
      //  获取消息提醒
      this.$post('/api/message/getSysMessages', {
        "pageNum": 1,
        "pageSize": 1,
        "type": 0
      }).then(res => {
        this.$store.state.unReadTotalNum = res.unReadTotalNum
        if (this.$store.state.unReadTotalNum == 0) {
          this.$store.state.unReadTotalNum = ''
        }
      })
    }
    this.$store.dispatch('getMechanismList');
  },
  methods: {
    logout() {

      this.$store.commit('LOGOUT')
      //重复路由跳转
      if (this.$route.name != 'Home') {
        this.$router.replace("/")
      }
      this.$store.commit('logntype', 2)
      this.$store.state.release = false;
    },
    goWorks() {
      if (this.$route.name == 'Works') {
        return false
      }
      // this.$router.replace('/works')
    },
    gomessage() {
      if (this.$route.name == 'News') {
        return false
      }
      this.$router.push('/news')
    },
    gologin() {
      this.$store.commit('logntype', 2)
      if (this.$route.name != 'Home') {
        this.$router.push('/')
      }
    },
    moveinnow() {
      this.$store.commit('logntype', 1)
      this.$store.state.release = false
      if (this.$route.name != 'Home') {
        this.$store.state.check = true
            this.$router.push('/')
      }


    },
    swict(val, type = 0) {
      if(this.userInfo?.userCompanyVo?.id === val && this.userInfo?.webLoginType === 1 )return
      // 根据切换不同的账号，首页点击发布作品，跳转不同的页面
      localStorage.setItem("release", val)
      this.$post('/api/company/identityChange', {id:val, type}).then((res) => {
        let data = res.data
        if (res.code == 0) {
          this.$store.commit('LOGIN', {
            secret: data.secret,
            session: data.session,
            user: data.user
          })
            this.$post('/api/user/userMsg').then(res => {
              this.$store.commit('hiddenBut', res.data)
              this.$store.commit('UMSG', res.data)
              this.$store.state.release = true;
              if(data.user.webLoginType === 1){
                console.log(data.user?.userCompanyVo?.status,'status');
                if(data.user?.userCompanyVo?.status !== 1 ){
                  this.$router.push('/certification/examine');
                }else {
                  console.log('123')
                  this.$nextTick(() =>{
                    this.$router.replace('/create').catch(() => {
                      console.log('路由重复跳转');
                    });
                  })
                }
              }else {
                if([1,2].includes(data.verifiedStatus)){
                  this.$router.push('/Musiciancon')
                }else {
                  this.$router.replace('/create').catch(() => {
                    console.log('路由重复跳转');
                  });
                }
              }
              this.$popup({msg: '身份切换成功', type: 'success'})
            })
        }
      })
    },
    approval(val) {
      if (val == 0) {
        if (this.$route.name == "Certify") {
          return
        }
        this.$router.replace("/Certify")
      } else {

        if (this.$route.name == "certificationFrom") {
          return
        }
        this.$router.replace("/certification/certificationFrom")
      }
    },
    jump() {
      if (this.$route.name == 'Certification' || this.$route.name == 'Certify') {
        return
      }
      if (this.user.webLoginType == 0) {
        if (this.user.verifiedStatus == 3) {
          this.$router.push('/create')
        } else if (this.user.verifiedStatus == 2 || this.user.verifiedStatus == 1) {
          this.$router.push('/musiciancon')
        } else {
          if (this.$route.name != 'Certify') {
            this.$router.push('/certify')
          }

        }
      } else if (this.user.webLoginType == 1) {
        if (this.user.userCompanyVo.status == 1) {
          this.$router.push('/create')
        } else if (this.user.userCompanyVo.status == 0 || this.user.userCompanyVo.status === 3 || this.verifiedStatus == 2) {
          this.$router.push('/certification/examine')
        } else {
          if (this.$route.name != 'Certification') {
            this.$router.push('/certification')
          }
        }
      }
    },
    },
  watch: {
    '$route.name': function (newval) {
      if (newval === 'Activity' || newval === 'Consulthome') {
        this.backflag = true
      } else {
        this.backflag = false
      }

      if (newval == 'Certify' || newval == 'certificationFrom') {
        this.falg = true;
      }

    }
  }
}
</script>
