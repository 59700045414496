<template>
  <transition name="alter">
    <div  class="content" v-if="flag">
      <div class="title">
        <div>
          <img :src="arr[type]" alt="" /><span>{{ title }}</span>
        </div>
        <div @click="fn" style="cursor: pointer">×</div>
      </div>
      <p>{{ msg }}</p>
    </div>
  </transition>
</template>
<script>
import success from '@/assets/success.png'
import warning from '@/assets/warning.png'
import error from '@/assets/error.png'
export default {
  data () {
    return {
      arr: { success, warning, error},
      flag: false,
      msg: '123',
      title: '提示',
      type: 'warning',
      duration: 800,
    };

  },
  methods: {
    fn () {
      this.flag = !this.flag;
    }
  }
}
</script>
<style scoped lang="scss">
.content {
  width: 378px;
  min-height: 80px;
  box-sizing: border-box;
  background-color: #ffffff;
  position: fixed;
  z-index: 9999;
//   top:50px;
   top: 0px;
   transform: translateY(50px);
  left: calc(50% - 189px);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #eaeaea;
  border-radius: 2px;
  padding-top: 20px;
   animation: show .5s;
  .title {
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    img {
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
      margin-left: 10px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
    }
  }
  p {
    margin: 4px 0 0 0;
    padding-left: 50px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #666660;
    padding-bottom: 18px;
  }
}
.alter-enter {
 transform: translateY(-50px);
  opacity: 1
}
.alter-enter-to {
 transform: translateY(50px);

}
.alter-leave {
 transform: translateY(50px);
   opacity: 1;
}
.alter-leave-to {
 transform: translateY(-50px);
 opacity:0;

}
.alter-leave-active {
  transition: all 1s;
}
@keyframes show {
    from{ transform: translateY(-30px); opacity: 0;}
    to{transform: translateY(50px); opacity: 1;}
}
</style>