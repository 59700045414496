<template>
  <div class="logo">
    <img src="../assets/logo.png" class="logo" @click="goHome" />
    <span class="name" @click="goHome">海葵分贝</span>
    <span class="perverse"></span>
    <span class="right-name" @click="goHome">音乐共创平台</span>
    <ul class="navbar">
      <li @click="jump(0)">
        首页
        <div class="divbom" :class="Routername == 'Home' ? 'der' : ''"></div>
      </li>
      <li v-if="userMsgHide" @click="jump(3)">
        创作中心
        <div class="divbom" :class="Routername == 'Create' ? 'der' : ''"></div>
      </li>
      <li @click="jump(1)">
        活动中心
        <div
          class="divbom"
          :class="Routername == 'Activity' ? 'der' : ''"
        ></div>
      </li>
      <li @click="jump(2)">
        资讯
        <div
          class="divbom"
          :class="information.indexOf(Routername)>-1 ? 'der' : ''"
        ></div>
      </li>
      <li  @click="jump(4)">
        帮助中心
        <div class="divbom" :class="Routername == 'HelpHome' ? 'der' : ''"></div>
      </li>
    </ul>
  </div>
</template>
<script>
//  资讯白名单
 const information =['Consulthome','Details']
// const tabList = [{name:'首页',id:'Home'},{name:'创作中心',id:'Create'},{name:'活动中心',id:'Activity'},{name:'资讯',id:'Consulthome'},{name:'帮助中心',id:'HelpHome'}]
export default {
  created () {
    this.Routername = this.$route.name;
  },
  data () {
    return {
      Routername: '',
      information,
    };
  },
  computed: {
     userMsgHide(){
       if(!this.$store.state.usermsg){
         return this.$store.state.usermsg
       }
     if(this.$store.state.usermsg.webLoginType === 0){
       if(this.$store.state.usermsg.verifiedStatus === 3){
         return true
       }else {
         return false
       }
     }else {
       if(this.$store.state.usermsg.userCompanyVo && this.$store.state.usermsg.userCompanyVo.status === 1){
         return true
       }else {
         return false
       }
     }

     }
  },
  methods: {
    goHome () {
      if (this.$route.name != 'Create') {
        this.$router.replace('/create')
      }
    },
    jump (index) {
      if (index === 0) {
        if (this.$route.name != 'Home') {
          this.$router.replace('/')
          this.Routername = this.$route.name
        }
      } else if (index === 1) { //活动中心
        if (this.$route.name != 'Activity') {
          this.$router.replace('/activity')
          this.Routername = this.$route.name
        }
      } else if (index === 2) { //资讯
        if (this.$route.name != 'Consulthome') {
          this.$router.replace('/consulthome')
          this.Routername = this.$route.name
        }
      } else if (index === 3) { //创作中心
        this.$router.replace('/create')
      } else if(index === 4){ //帮助中心
        this.$router.replace('/help-home').catch(()=>{
          console.log('路由重复跳转')
        })
      }
    }
  },
  watch: {
    '$route.name': function (newval) {
      this.Routername = newval
    }
  }
}
</script>
<style lang="scss" scoped>
.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  .logo {
    width: 34px;
    height: 34px;
  }
  .name {
    height: 21px;
    font-size: 15px;
    font-family:PingFang SC, PingFang SC-Semibold;;
    font-weight: 600;
    color: #ffffff;
    line-height: 21px;
    margin-left: 8px;
    text-align: left;
  }
  .perverse{
    display: inline-block;
    background: #ffffff;
    height: 15px;
    width: 1px;
    margin: 0px 15px;
  }
  .right-name{
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Light;
    font-weight: 300;
    text-align: LEFT;
    color: #ffffff;
  }
  .navbar {
    padding: 0px;
    display: flex;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #ffffff;
    list-style: none;
    li {
      padding-top: 10px;
      margin-left: 60px;
      cursor: pointer;
      position: relative;
      .der {
        margin: 0 auto;
        margin-top: 6px;
        width: 28px;
        height: 3px;
        background-color: #ffffff;
      }
      .divbom {
        width: 28px;
        height: 3px;
         margin-top: 6px;
      }
    }
  }
}
</style>
