<template>
    <div class="copyright" :class="backflag?'bg':''">
        <div>© 2017-2022 Hikoon Copyright 版权所有</div>
        <div class="margintop"><a href="https://beian.miit.gov.cn/">京ICP备 17046250号</a> | 京公网安备 11011202000841号</div>
    </div>
</template>
<script>

export default{
 data() {
     return {
         backflag: 'false',
     };
 },
 created(){
     //  添加背景颜色
       if(this.$route.name==='Activity'||this.$route.name==='Consulthome'){
           this.backflag=true
       }else{
         this.backflag=false
       }
 },
 watch:{
    '$route.name'(newval){
          //  添加背景颜色
       if(newval==='Activity'||newval==='Consulthome'){
           this.backflag=true
       }else{
             this.backflag=false
       }
    }
 }

}
</script>

<style lang="scss" scoped>
.copyright{
    /* min-height: 57px; */
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #787878;
    /* line-height: 20px; */
    .margintop{
        margin-top: 5px;
    }
    a{
        text-decoration: none;
        color: inherit;
    }
}
div.bg{
    background-color: #0E0E0E
}
</style>
